import React, { useState, useEffect } from "react";
import Axios from "axios";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import { ErrorMessage, Field } from "formik";

const LabelStyle = style((theme) => ({
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  lineHeight: "38px",
  color: theme.color,
}));

const InputStyle = style(() => ({
  borderRadius: "20px",
  border: 0,
  backgroundColor: "#e0e0e0",
  color: "#395B50",
  textAlign: "center",
  fontWeight: "bold",
  width: "190px",
  height: "30px",
  "&:focus": {
    outline: "none",
    caretColor: "transparent",
  },
}));

const ErrorMessageStyle = style(() => ({
  color: "#ff6961",
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "20px",
  fontFamily: "Fira Sans",
}));

const WordpackDropdown = () => {
  const inputStyle = useThemedStyle(InputStyle);
  const labelStyle = useThemedStyle(LabelStyle);
  const errorMessageStyle = useThemedStyle(ErrorMessageStyle);
  const [wordpacks, setWordpacks] = useState([]);

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_WORDPACKS).then((response) => {
      setWordpacks(response.data.wordpacks);
    });
  });

  return (
    <>
      <label className={labelStyle}>wordpack</label>
      <Field
        as='select'
        className={inputStyle}
        label='wordpack'
        name='wordpackOptions'
        type='text'
        autoComplete='off'
      >
        <option>use own words</option>
        {wordpacks.map((wordpack, index) => {
          return (
            <>
              <option
                id={index}
                key={wordpack.WORDPACK_NAME}
                value={wordpack.WORDPACK_NAME}
              >
                {wordpack.WORDPACK_NAME.toLowerCase()}
              </option>
            </>
          );
        })}
      </Field>

      <ErrorMessage
        component='div'
        name='wordpackOptions'
        className={errorMessageStyle}
      />
    </>
  );
};

export default WordpackDropdown;
