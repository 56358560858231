import React, { useState } from "react";
import Title from "../titles/Title";
import { style } from "themed-jss";
import { useThemedStyle } from "themed-jss/react";
import JoinGameForm from "./resources/JoinGameForm";

const RootStyle = style(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
}));

const ButtonSecondaryStyle = style((theme) => ({
  backgroundColor: theme.buttonSecondary,
  borderRadius: "20px",
  borderColor: "transparent",
  width: "190px",
  height: "30px",
  color: "#ffffff",
  fontFamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "15px",
  marginTop: "10px",
}));

const InfoMessageStyle = style((theme) => ({
  color: theme.switchButton,
  fontWeight: "bold",
}));

function JoinGame(props) {
  const [username] = useState("");
  const rootStyle = useThemedStyle(RootStyle);
  const buttonSecondaryStyle = useThemedStyle(ButtonSecondaryStyle);
  const infoMessageStyle = useThemedStyle(InfoMessageStyle);

  const onCreate = () => {
    props.createGame({ username: username });
  };

  return (
    <>
      <Title>associations</Title>
      <div className={rootStyle}>
        <JoinGameForm joinRoom={props.joinRoom} />

        <button className={buttonSecondaryStyle} onClick={onCreate}>
          CREATE ROOM
        </button>
      </div>
      {/* <p className={infoMessageStyle}>
        wordpacks should be tested @{" "}
        <a
          href='https://uat.associations-game.com/'
          className={infoMessageStyle}
        >
          uat.associations-game.com
        </a>
      </p> */}
    </>
  );
}
export default JoinGame;
